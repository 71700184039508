module controllers {
    export module tariff {

        interface IMultipleProductTariffParams extends ng.ui.IStateParamsService {
            productIds: Array<number>;
            tariffCode: interfaces.tariff.ITariffCode;
        }


        export class multipleProductTariffCtrl {

            static $inject = [
                '$scope',
                '$rootScope',
                '$q',
                '$uibModal',
                'generalService',
                'entityService',
                'bsLoadingOverlayService',
                'countryService',
                'classificationValueService',
                'hsCodeService',
                '$timeout',
                "$stateParams",
                'productTariffService',
                '$anchorScroll',
                '$state'
            ];

            statusList: interfaces.applicationcore.IDropdownModel[] = [];
            productIds: Array<number>;
            tariffCode: interfaces.tariff.ITariffCode;
            apiTariffDetailList: uiGrid.IGridApi;
            simulationDate: moment.Moment = moment.utc();
            ProductTariffDetailList: Array<interfaces.tariff.IProductTariffDetail>[] = [];

            constructor(
                private $scope: ng.IScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private $q: ng.IQService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private generalService: interfaces.applicationcore.IGeneralService,
                private entityService: interfaces.applicationcore.IEntityService,
                private bsLoadingOverlayService,
                private countryService: interfaces.applicationcore.ICountryService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                private hsCodeService: interfaces.tariff.IHsCodeService,
                public $timeout: ng.ITimeoutService,
                private $stateParams: IMultipleProductTariffParams,
                public productTariffService: services.tariff.productTariffService,
                private $anchorScroll: ng.IAnchorScrollService,
                private $state: ng.ui.IStateService,
            ) {

                this.productIds = $stateParams.productIds;
                this.tariffCode = $stateParams.tariffCode;

                var loadPromises: ng.IPromise<any>[] = [];
                
                loadPromises.push(this.multipleProductTariff(this.productIds, this.tariffCode));

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'pt.multiple' },
                    () => {
                        return $q.all(loadPromises);
                    });
            }

            multipleProductTariff(productIds: Array<number>, tariffCode: interfaces.tariff.ITariffCode): ng.IPromise<boolean>{

                var deferre = this.$q.defer<boolean>();

                this.productTariffService.MultipleProductTariff(productIds, tariffCode.tariffCode, tariffCode.countryId, tariffCode.codeTypeClassificationValueId, tariffCode.statusId,tariffCode.startDate.format("YYYYMMDDTHH:mm")).save({ }, (data: interfaces.applicationcore.IMessageHandler) => {
                    this.generalService.displayMessageHandler(data);                   

                    this.loadData(productIds);

                    deferre.resolve(true);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferre.resolve(false);
                });

                return deferre.promise;
            }


            loadData(productIds: Array<number>) {
                this.productTariffService.GetMultipleProductTariffingGrid(productIds).query({}, (data: Array<interfaces.tariff.IProductTariffDetail>) => {

                    this.gvwTariffDetail.data = data;
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }
                );
            }

            gvwTariffDetail: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                cellEditableCondition: true,
                showGridFooter: false,
                onRegisterApi: (gridApi) => { this.registerTariffDetailGridApi(gridApi); },
                columnDefs: [
                    {
                        name: "EDIT",
                        displayName: "",
                        enableFiltering: false,
                        enableCellEdit: false,
                        cellTemplate: `
                        <div class="GridButton"> 
                                <button type="button" ng-click="grid.appScope.multipleProductTariffCtrl.DeleteTariff(row.entity)" class="btn btn-warning btn-sm">
                                    Delete
                                </button>
                        </div>`,
                        enableSorting: false,
                        enableColumnMenu: false,
                        width: 65
                    },
                    {
                        name: 'Product',
                        displayName: 'Product',
                        field: 'ProductCode',
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{row.entity.ProductCode}} - {{row.entity.ProductDescription}}</div>`,
                        enableSorting: false,
                        enableCellEdit: false,
                        width: 250
                    }
                    ,
                    {
                        name: 'COO',
                        displayName: '* Country of Origin',
                        field: 'OriginCountry',
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{row.entity.OriginCountryCode}} - {{row.entity.OriginCountryDescription}}</div>`,
                        enableSorting: false,
                        enableCellEdit: true,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ng-change="grid.appScope.multipleProductTariffCtrl.cooChanged(model, row.entity)" ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.multipleProductTariffCtrl.loadCountries()"></gts-dropdown>
                                </form>`,
                        width: 180
                    },
                    {
                        name: 'Country',
                        displayName: 'Country',
                        field: 'CountryCode',
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{row.entity.CountryCode}}</div>`,
                        enableSorting: false,
                        enableCellEdit: false,
                        width: 100
                    },
                    {
                        name: 'CODE',
                        displayName: 'Code',
                        field: 'TariffCode',
                        enableSorting: false,
                        enableCellEdit: false,
                        cellTemplate: `<div style="color:#8bac00;text-decoration:underline;" class="ui-grid-cell-contents" ng-click="grid.appScope.multipleProductTariffCtrl.showTariffDescription_Click(row.entity)"> 
                                            {{row.entity.TariffCode}}
                                     </div>`,
                        width: 100
                    },
                    {
                        name: 'DUTYTYPE',
                        displayName: 'Type',
                        field: 'DutyType',
                        enableSorting: false,
                        enableCellEdit: false,
                        width: 50
                    },
                    {
                        name: "STARTDATE",
                        displayName: "Start Date",
                        field: "StartDate",
                        width: 100,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: 'YYYY/MM/DD'}}</div>`
                    },
                    {
                        name: 'ENDDATE',
                        displayName: 'End Date',
                        field: 'EndDate',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                        <gts-date format="yyyy/MM/dd" ng-model="MODEL_COL_FIELD" name="{{MODEL_COL_FIELD.$$hashKey}}-endDate"></gts-date>
                                </form>`,
                        enableSorting: false,
                        width: 100
                    },
                    {
                        name: 'STATUS',
                        displayName: 'Status',
                        field: 'Status',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{row.entity.StatusDescription}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ng-change="grid.appScope.multipleProductTariffCtrl.statusChanged(model, row.entity)" ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.multipleProductTariffCtrl.loadStatuses()"></gts-dropdown>
                                </form>`,
                        enableSorting: false,
                        width:120
                    },
                    {
                        name: 'STATUNIT',
                        displayName: 'Stat. Unit',
                        field: 'StatisticalUoM',
                        enableSorting: false,
                        enableCellEdit: false,
                        width: 100
                    },
                    {
                        name: 'TRADEAGREEMENT',
                        displayName: 'Trade Agreement',
                        field: 'TradeAgreement',
                        enableSorting: false,
                        enableCellEdit: false,
                        width: 120
                    },
                    {
                        name: 'CRITERIA',
                        displayName: 'Criteria',
                        field: 'OriginCriteria',
                        enableSorting: false,
                        enableCellEdit: false,
                        width: 100
                    },
                    {
                        name: 'DUTY',
                        displayName: 'Duty',
                        field: 'DutyFormula',
                        enableSorting: false,
                        enableCellEdit: false,
                        width: 100
                    },
                    {
                        name: 'PR',
                        displayName: 'P&R',
                        field: 'PR',
                        enableSorting: false,
                        enableCellEdit: false,
                        cellTemplate: ` <center>
                                            <div>
                                                    <span ng-if="row.entity.PR === 0" style="color: #bcd856;" class="fa fa-check"></span>
                                                    <span  ng-if="row.entity.PR === 1"class="fa fa-warning" style="color: #f57f20;"></span>
                                                    <span  ng-if="row.entity.PR === 2" class="fa fa-warning" style="color: #cb2330;"></span>
                                            </div>
                                        </center>`,
                        width: 50
                    }, {
                        name: 'VIEW',
                        displayName: 'View',
                        field: '',
                        enableSorting: false,
                        enableCellEdit: false,
                        cellTemplate: `<div>
                                           <gts-tariff-detail   national-measure-code="row.entity.TariffCode" 
                                                                duty-type-code="row.entity.DutyType"
                                                                duty-type-id="row.entity.CodeTypeClassificationValueId"
                                                                country-id="row.entity.CountryId"
                                                                simulation-date="grid.appScope.productTariffUpdateCtrl.simulationDate">
                                          </gts-tariff-detail>
                                    </div>`,
                        width: 50
                    },
                ]
            };

            registerTariffDetailGridApi(gridApi: uiGrid.IGridApi) {
                this.apiTariffDetailList = gridApi;

                this.apiTariffDetailList.cellNav.on.navigate(this.$scope, (gridSelection) => {
                    this.apiTariffDetailList.selection.selectRow(gridSelection.row.entity);
                });

                /*this.$timeout(() => {
                    if (this.productTariff) {
                        this.gvwTariffDetail.data = this.productTariff.ProductTariffDetailList;
                    }
                });*/
            }

            DeleteTariff(entity: interfaces.tariff.IProductTariffDetail) {
                this.generalService.displayConfirmationBox("Confirm?", "Change tariff status to Inactive?").then((result: boolean) => {
                    if (result) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'pt.multiple'
                        },
                            () => {

                                entity.StatusId = Enum.ProductTariffStatus.Inactive;

                                if (this.apiTariffDetailList) {
                                    this.apiTariffDetailList.rowEdit.setRowsDirty([entity]);
                                }

                                return this.SavePT().then((data: boolean) => {
                                    if (data) {
                                        this.loadData(this.$stateParams.productIds);
                                        this.$anchorScroll("topAnchor");
                                        this.$state.go("auth.ProductTariff.MultipleProductTariff", { productIds: this.productIds, tariffCode: <interfaces.tariff.ITariffCode>this.tariffCode });
                                    }
                                });
                            });
                    }
                });
            }

            showTariffDescription_Click(entity: interfaces.tariff.IProductTariffDetail) {
                this.DisplayTariffDescription(entity.TariffDescription);
            }

            DisplayTariffDescription(TariffDescription: string) {
                //Get delivery status
                return this.$uibModal.open({
                    animation: true,
                    template: `
                        <div class="modal-content" >
                            <form name="tariffNotes">
                                <div class="gts-padding">
                                    <fieldset id="tariffNotes">
                                    <legend>
                                       Tariff Description
                                    </legend>                                            
                                            <div class="container-fluid row">
                                                   <p ng-bind-html="tariffDescriptionCtrl.TariffDescription"></p>
                                             </div>  
                                            <div class="gts-padding pull-right">                                               
                                                    <button type="button" class="btn btn-primary"  ng-click="tariffDescriptionCtrl.ok()">Ok</button>                                                   
                                            </div>                                       
                                    </fieldset>
                                </div>
                            </form>
                        </div>
                    `
                    ,
                    controller: class tariffDescriptionCtrl {
                        TariffDescription: string;
                        constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService, $sce) {
                            this.TariffDescription = $sce.trustAsHtml(TariffDescription);
                        }

                        ok() {

                            this.$uibModalInstance.close();
                        }

                    },
                    controllerAs: "tariffDescriptionCtrl",
                    size: "sm",
                    resolve: {
                    }
                }).result;
            }

            statusChanged(model: interfaces.applicationcore.IDropdownModel, entity: interfaces.tariff.IProductTariffDetail) {
                if (model) {
                    entity.StatusId = model.Id;
                    entity.StatusDescription = model.Display;
                }
            }

            cooChanged(model: interfaces.applicationcore.IDropdownModel, entity: interfaces.tariff.IProductTariffDetail) {
                if (model) {
                    entity.OriginCountryId = model.Id;
                    entity.OriginCountryCode = model.Code;
                    entity.OriginCountryDescription = model.Description;

                    if (this.apiTariffDetailList) {
                        this.apiTariffDetailList.rowEdit.setRowsDirty([entity]);
                    }

                }
            }


            loadStatuses() {

                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.ProductTariffCountryStatus
                }, (resultList) => {
                    this.statusList = resultList;
                }).$promise;

            }

            loadCountries() {
                var defered = this.$q.defer();

                this.countryService.getDropdownList().query({}, (data: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(data);
                }, () => {
                    defered.resolve([]);
                });
                
                return defered.promise;
            }
            
            Save() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'pt.multiple'
                },
                    () => {
                        return this.SavePT().then((data: boolean) => {
                            if (data) {
                                this.loadData(this.$stateParams.productIds);

                                this.$anchorScroll("topAnchor");
                                this.$state.go("auth.ProductTariff.MultipleProductTariff", { productIds: this.productIds, tariffCode: <interfaces.tariff.ITariffCode>this.tariffCode });
                            }
                        });
                    });
            }

            SaveClose() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'pt.multiple'
                },
                    () => {
                        return this.SavePT().then((data) => {
                            if (data) {
                                this.$state.go("^");
                            } else {
                                this.$anchorScroll("topAnchor");
                            }
                        });
                    });
            }

            Close() {
                this.$state.go("^");
            }

            SavePT(): ng.IPromise<boolean> {
                var deferre = this.$q.defer<boolean>();

                if (this.apiTariffDetailList) {
                    this.ProductTariffDetailList = _.map(this.apiTariffDetailList.rowEdit.getDirtyRows(this.apiTariffDetailList.grid), (o) => { return o.entity });
                } else {
                    this.ProductTariffDetailList = [];
                }
          
                this.productTariffService.SaveChangesToMultipleProductTariffGrid().save(this.ProductTariffDetailList, (data: interfaces.applicationcore.IMessageHandler) => {
                    this.generalService.displayMessageHandler(data);

                    if (this.apiTariffDetailList) {
                        this.apiTariffDetailList.rowEdit.setRowsClean(this.ProductTariffDetailList);
                    }               

                    deferre.resolve(true);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferre.resolve(false);
                });

                return deferre.promise;
            };
        };

        angular.module("app").controller("multipleProductTariffCtrl", controllers.tariff.multipleProductTariffCtrl);
    }
}